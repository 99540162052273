<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy text-uppercase">Fundraiser</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-cols">
      <div class="pre-col-1-1">
        <div class="pre-pad"></div>
        <div class="pre-headline">Paranormal Institute and Computer Aid</div>
        <div class="pre-pad"></div>
        <div class="pre-copy">
          <div class="row">
            <div class="col-12 col-md-3 align-self-center text-center">
              <img
                class="img-fluid"
                src="/assets/images/computer-aid-logo.png"
                alt="Computer Aid"
              />
            </div>
            <div class="col-12 col-md-9">
              <p>
                Here at the Paranormal Institute, we know the importance of
                technology. Dr. Egon Spengler used his knowledge of computing to
                create SPIRIT NET (Spengler's Paranormal Interactive Relayable
                Intercontinental Telecommunications Network) so we could bring
                our teaching of ghouls, ghosts and spirits to students all over
                the world.
              </p>
              <p>
                We wanted to help others have the same access to digital
                technology, so we have partnered with Computer Aid, a charity
                enhancing and supporting learning environments in the developing
                world by providing computers and training, whilst giving a new
                home to old equipment that would otherwise be discarded.
              </p>
            </div>
          </div>
        </div>
        <div class="pre-pad"></div>
        <div class="pre-subhead">The Campaign</div>
        <div class="pre-copy">
          <p>
            We are focusing our fundraising on a digital schools project, which
            will:
          </p>
          <ul>
            <li>
              Set up computer labs in schools in Koforidua in the Eastern region
              of Ghana.
            </li>
            <li>
              Focus on reaching out to girls of all age groups to encourage them
              to continue in education, and to provide digital competencies and
              teaching in the digital sciences.
            </li>
            <li>Provide teacher development programmes.</li>
          </ul>
        </div>
        <div class="pre-pad"></div>
        <div class="row">
          <div class="col-12 col-md-6 align-self-center">
            <div class="pre-subhead">About Computer Aid</div>
            <div class="pre-copy">
              <p>Since 1997 Computer Aid has:</p>
              <ul>
                <li>Helped over 14.5 million people worldwide.</li>
                <li>Provided over 260,000 computers in over 100 countries</li>
                <li>Enabled over 1 billion hours of learning.</li>
              </ul>
            </div>
            <div class="pre-pad"></div>
            <div class="pre-copy"></div>
          </div>
          <div class="col-12 col-md-6">
            <lazy-img
              class="pre-image mx-auto"
              src="/assets/images/computer-aid-school.png"
              alt="Image from Computer Aid's Digital Schools project in Rwanda"
            />
            <small class="mx-auto"
              >Image from Computer Aid's Digital Schools project in
              Rwanda</small
            >
          </div>
          <div class="col-12">
            <div class="pre-pad"></div>
            <p>
              <a href="https://www.computeraid.org/" target="_blank"
                >https://www.computeraid.org/</a
              >
            </p>
          </div>
          <div class="col-12 col-md-6 offset-md-3 text-center">
            <h3>£{{ Math.round((counter + Number.EPSILON) * 100) / 100 }}</h3>
            <div class="progress mb-4" style="height: 30px">
              <div
                id="pre-progress-bar"
                class="progress-bar"
                role="progressbar"
                :style="`width:${percentageRaised}%; transition-duration:${animationDuration}s`"
                :aria-valuenow="percentageRaised"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <a :href="donationURL" target="_blank">
              <pnw-button variant="primary" size="xl"> Donate </pnw-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PnwButton from "../components/PnwButton.vue";

export default {
  components: { PnwButton },
  name: "Fundraiser",
  data() {
    return {
      animationDuration: 10,
      appId: "159ab367",
      guid: "cdc3aea9-ad79-400c-921b-c66a44cab418",
      donationURL:
        "https://www.justgiving.com/campaign/secret-cinema-computer-aid",
      counter: 0,
      totalRaised: 0,
      percentageRaised: 0,
      fundraisingTarget: 10000,
    };
  },
  mounted() {
    axios
      .get(
        `https://api.justgiving.com/${this.appId}/v1/campaign/byguid/${this.guid}/totals`
      )
      .then((response) => {
        this.totalRaised = response.data.CampaignTotals.TotalAmount;
        this.fundraisingTarget = response.data.TargetAmount;
        document.addEventListener("scroll", this.triggerAnimation);
        this.triggerAnimation();
      });
  },
  destroyed() {
    document.removeEventListener("scroll", this.triggerAnimation);
  },

  methods: {
    countUp() {
      if (this.counter < this.totalRaised) {
        setTimeout(() => {
          this.counter += this.totalRaised / (this.animationDuration * 100);
          this.countUp();
        }, this.animationDuration);
      }
    },
    triggerAnimation() {
      let el = document.getElementById("pre-progress-bar");
      let top = el.offsetTop;
      let height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
      }

      if (
        top >= window.pageYOffset &&
        top + height <= window.pageYOffset + window.innerHeight
      ) {
        this.countUp();
        this.percentageRaised = Math.round(
          (this.totalRaised / this.fundraisingTarget) * 100,
          2
        );
        document.removeEventListener("scroll", this.triggerAnimation);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  line-height: 1.8;
}
ul {
  margin-left: 3rem;
  margin-top: 1rem;
  font-size: 18px;
}
.progress-bar {
  background-color: #6bca76;
}
.pre-headline{
  font-size: 42px;
}
</style>
